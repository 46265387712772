import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <Fragment>
      <div className="looper-wave grad-blue-blue h-screen relative">
        <div
          className="container w-full md:w-1/3 flex flex-wrap items-center animated fadeIn "
          style={{ paddingTop: 5 + "%" }}
        >
          <Link
            to="/"
            className="ml-4 inline-block border-2 font-bold uppercase text-sm tracking wide px-4 py-2 leading-none rounded-full text-gray-400 border-white hover:border-white hover:text-teal-500 hover:bg-white mt-4 lg:mt-4 lg:mr-4 mr-2 mt-2 mb-6"
          >
            <i className="fas fa-angle-left" /> Back to Search
          </Link>

          <h1 className="block w-full font-bold uppercase text-sm tracking-wide text-white mx-6 pb-4">
            About
          </h1>

          <p className="md:text-left flex-auto md:pl-0 mx-6 text-white text-2xl md:text-2xl font-hairline tracking-wide pr-8 pb-4 md:pb-0">
            A fun experiment built using the Github API, React, and Tailwind.
            Designed to feel fast and modern.
          </p>
          <ul className="text-white text-left mt-8 mx-6">
            <li className="w-full font-bold uppercase text-sm tracking-wide pb-4">
              Features
            </li>
            <li className="w-full font-hairline">
              <i className="fa fa-check pr-2" /> User Search
            </li>
            <li className="w-full font-hairline">
              <i className="fa fa-check pr-2" /> Github User Stats
            </li>
            <li className="w-full font-hairline">
              <i className="fa fa-check pr-2" /> Pulls in last 20 Repos
            </li>
            <li className="w-full font-hairline">
              <i className="fa fa-check pr-2" /> Displays last year of Gists
            </li>
            <li className="text-sm font-semibold uppercase pt-8 tracking-wide text-purple-400">
              Built by Scott Hargroves
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
