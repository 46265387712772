import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import User from './components/users/User';
import Gist from './components/gists/Gist';
import Alert from './components/layout/Alert';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Art from './components/pages/Art';
import NotFound from './components/pages/NotFound';

//IMPORT STATE FROM CONTEXT PROVIDERS
import GithubState from './context/github/GithubState';
import AlertState from './context/alert/AlertState';

import './App.css';

const App = () => {
  return (
    <GithubState>
      <AlertState>
        <Router>
          <div className='App'>
            <Navbar />
            <Alert />
            <Switch>
              <Route exact path='/search' component={Home} />
              <Route exact path='/' component={Art} />
              <Route exact path='/about' component={About} />
              <Route exact path='/user/:login' component={User} />
              <Route exact path='/user/:login/:id' component={Gist} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </AlertState>
    </GithubState>
  );
};

export default App;
