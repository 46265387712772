import React from 'react';
import PropTypes from 'prop-types';

const GistItem = ({ user, gist }) => {
  return (
    <a
      href={gist.html_url}
      target='_blank'
      rel='noopener noreferrer'
      className='w-full md:w-1/2'
    >
      <div className='gist relative mx-4 mb-4 px-4 py-4 rounded bg-gray-200 hover:bg-white hover:bg-teal-200 border-white border hover:border-teal-300'>
        {gist.description === '' && (
          <p className='text-gray-700 text-md'>Unamed Gist</p>
        )}
        {gist.description !== '' && (
          <p className='text-gray-700 text-md'>{gist.description}</p>
        )}
        <div class='pr-6 pt-4'>
          <span class='inline-block bg-gray-800 rounded-full px-3 py-1 text-sm font-semibold text-gray-300 mr-2'>
            <i class='fas fa-comment text-grey-400' /> {gist.comments}
          </span>
        </div>
      </div>
    </a>
  );
};

GistItem.propTypes = {
  gist: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default GistItem;
