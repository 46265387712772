import React from 'react';

const NotFound = () => {
  return (
    <div className='w-full h-full'>
      <div className='w-1/3 mt-8 pt-8 ml-auto mr-auto text-center text-gray-600 text-2xl font-hairline'>
        ¯\_(ツ)_/¯
      </div>
      <h1 className='w-1/3 ml-auto mr-auto text-center text-gray-600 text-2xl font-hairline'>
        Oops, this page doesn't exist
      </h1>
    </div>
  );
};

export default NotFound;
