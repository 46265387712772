import React from "react";
import PropTypes from "prop-types";

const RepoItem = ({ repo }) => {
  return (
    <a
      href={repo.html_url}
      target="_blank"
      rel="noopener noreferrer"
      className="w-full md:w-full"
    >
      <div className="relative mx-4 my-4 px-4 py-4 rounded-lg bg-gray-300 hover:bg-white hover:bg-teal-200 border-white border hover:border-teal-300">
        <h3 className="text-2xl text-teal-900">{repo.name}</h3>
        <span className="absolute top-0 right-0 mr-4 mt-4 text-gray-700 text-sm">
          {repo.updated_at}
        </span>
        <p className="text-gray-700 text-md">{repo.description}</p>

        <div class="pr-6 pt-4">
          <span class="inline-block bg-gray-800 rounded-full px-3 py-1 text-sm font-semibold text-gray-300 mr-2">
            <i class="fas fa-star text-orange-400" /> {repo.stargazers_count}
          </span>

          <span class="inline-block bg-gray-800 rounded-full px-3 py-1 text-sm font-semibold text-gray-300 mr-2">
            <i class="fas fa-eye text-grey-400" /> {repo.watchers_count}
          </span>
        </div>
        <div className="absolute bottom-0 right-0 mr-4 text-xl md:text-4xl text-gray-600">
          {repo.language === "JavaScript" && (
            <i class="fab fa-js-square" title="Javascript" />
          )}
          {repo.language === "CSS" && <i class="fab fa-css3-alt" title="CSS" />}
          {repo.language === "Java" && <i class="fab fa-java" title="Java" />}
          {repo.language === "PHP" && <i class="fab fa-php" title="PHP" />}
          {repo.language === "TypeScript" && (
            <i class="fab fa-ts" title="TypeScript" />
          )}
          {repo.language === "VueJS" && (
            <i class="fab fa-vuejs" title="VueJS" />
          )}
        </div>
      </div>
    </a>
  );
};

RepoItem.propTypes = {
  repo: PropTypes.object.isRequired,
};

export default RepoItem;
