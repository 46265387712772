import React, { Fragment, useEffect, useContext } from 'react';
import SkeletonUser from '../layout/SkeletonUser';
import Repos from '../repos/Repos';
import Gists from '../gists/Gists';
import { Link } from 'react-router-dom';
import GithubContext from '../../context/github/githubContext';
import UserChart from '../users/UserChart';

const User = ({ match }) => {
  const githubContext = useContext(GithubContext);

  const {
    getUser,
    loading,
    user,
    repos,
    gists,
    getUserRepos,
    getUserGists,
    data
  } = githubContext;

  useEffect(() => {
    getUser(match.params.login);
    getUserRepos(match.params.login);
    getUserGists(match.params.login);
    // eslint-disable-next-line
  }, []);

  const {
    name,
    avatar_url,
    location,
    bio,
    blog,
    html_url,
    followers,
    public_repos,
    public_gists,
    hireable
  } = user;

  if (loading) return <SkeletonUser />;
  return (
    <Fragment>
      <div className='flex'>
        <div className='w-full sm:w-3/3 m-auto'>
          <Link
            to='/search'
            className='ml-4 inline-block font-bold uppercase text-sm tracking wide px-4 py-2 leading-none rounded-full text-orange-600 border-orange-500 hover:border-orange-300 hover:text-orange-500 hover:bg-white mt-4 lg:mt-4 lg:mr-4 mr-2 mt-2'
          >
            <i className='fas fa-angle-left' /> Back to Search
          </Link>
          <div className='flex flex-wrap card shadow-lg hover:shadow-xl bg-white mt-4 mb-8 animated fadeIn fast'>
            <div className='w-full lg:w-1/4 lg:border-r border-gray-200 pt-6'>
              <img
                src={avatar_url}
                className='w-24 rounded-full ml-auto mr-auto animated fadeInDown'
                alt={name}
              />
              <div className='px-6 py-4 relative'>
                <p className='font-bold text-gray-800 text-2xl text-center'>
                  {name}
                </p>
                <p className='text-center uppercase text-xs text-gray-600 tracking-wide font-bold'>
                  {location}
                </p>
                {bio && (
                  <Fragment>
                    <p className='text-md text-gray-800 mt-8 font-hairline leading-relaxed'>
                      {bio}
                    </p>
                  </Fragment>
                )}
                {blog && (
                  <p className={`mt-4 ${bio ? 'text-left' : 'text-center'}`}>
                    <a
                      href={blog}
                      className='text-teal-500 hover:text-teal-700'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fas fa-link' /> {blog}
                    </a>
                  </p>
                )}

                <div className='block md:mt-8 md:pt-8 mt-4'>
                  <a
                    href={html_url}
                    className='text-center w-40 ml-auto mr-auto block bg-purple-800 rounded-full px-4 py-3 text-sm font-semibold text-purple-200'
                  >
                    <i className='fab fa-github' /> Launch Github
                  </a>
                </div>
                {hireable && (
                  <div className='w-full text-center absolute top-0 -mt-3 -ml-6 animated tada delay-1s'>
                    <span className='bg-green-200 rounded-full px-3 py-1 text-xs text-green-800 uppercase tracking-wide'>
                      <i className='fas fa-dollar-sign' /> Available for Hire
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='w-full lg:w-3/4 pb-6'>
              <div className='flex mb-4 text-center border-b md:pb-4 text-gray-700'>
                <div className='w-1/3 h-24 text-2xl md:text-4xl font-hairline pt-4 animated flipInX delay-1s'>
                  {followers}
                  <p className='hidden sm:block text-xs font-bold text-red-600 lg:tracking-widest uppercase'>
                    <i className='fas fa-user-friends' /> followers
                  </p>
                  <p className='sm:hidden block text-xs font-bold text-red-600 lg:tracking-widest uppercase'>
                    <i className='fas fa-user-friends' />
                  </p>
                </div>
                <div className='w-1/3 h-24 text-2xl md:text-4xl font-hairline pt-4 animated flipInX delay-1s'>
                  {public_repos}
                  <p className='hidden sm:block text-xs font-bold text-purple-700 tracking-wide uppercase'>
                    <i className='fas fa-code-branch' /> REPOS
                  </p>
                  <p className='sm:hidden block text-xs font-bold text-blue-700 lg:tracking-widest uppercase'>
                    <i className='fas fa-code-branch' />
                  </p>
                </div>
                <div className='w-1/3 h-24 text-2xl md:text-4xl font-hairline pt-4 animated flipInX delay-1s'>
                  {public_gists}
                  <p className='hidden sm:block text-xs font-bold text-blue-700 tracking-wide uppercase'>
                    <i className='fas fa-code' /> GISTS
                  </p>
                  <p className='sm:hidden block text-xs font-bold text-blue-700 lg:tracking-widest uppercase'>
                    <i className='fas fa-code' />
                  </p>
                </div>
              </div>
              <div className='w-full px-4'>
                <UserChart data={data} id='chart' />
              </div>

              <div className='block'>
                <div className='sticky top-0 px-4 text-2xl font-bold pb-4 bg-white z-40 text-purple-500 pt-2'>
                  <i className='fas fa-code-branch' /> Recent Repos
                </div>

                <div className='w-full flex flex-wrap'>
                  <Repos repos={repos} />
                </div>
                <div className='sticky top-0 px-4 text-2xl font-bold pb-4 bg-white z-40 text-blue-700 pt-2'>
                  <i className='fas fa-code' /> Recent Gists
                </div>
                <div className='w-full flex flex-wrap'>
                  <Gists gists={gists} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default User;
