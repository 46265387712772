import React, { Fragment, useContext } from 'react';
import UserItem from './UserItem';
import SkeletonSearch from '../layout/SkeletonSearch';
import GithubContext from '../../context/github/githubContext';

const Users = () => {
  const githubContext = useContext(GithubContext);

  const { loading, users } = githubContext;
  if (loading) {
    return <SkeletonSearch />;
  } else {
    return (
      <Fragment>
        <div className='w-full md:w-2/3 md:mr-auto md:ml-auto flex flex-wrap pb-8 mb-8'>
          {users.map(user => (
            <UserItem key={user.id} user={user} />
          ))}
        </div>
        <div className='md:h-24 md:block hidden'>{''}</div>
        {githubContext.users.length === 0 && (
          <div className='text-center text-gray-600 text-6xl animated pulse infinite mt-8 pt-8'>
            <i class='fab fa-github-alt' />
          </div>
        )}
      </Fragment>
    );
  }
};

export default Users;
