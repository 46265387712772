import React, { useState, useContext } from "react";
import GithubContext from "../../context/github/githubContext";
import AlertContext from "../../context/alert/alertContext";

const Search = () => {
  const githubContext = useContext(GithubContext);
  const alertContext = useContext(AlertContext);

  const [text, setText] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    document.getElementById("search").blur();
    if (text === "") {
      alertContext.setAlert(
        "Oops! You forgot to enter a search term.",
        "teal-200"
      );
    } else {
      githubContext.searchUsers(text);
      //setText('');
    }
  };

  const onChange = (e) => setText(e.target.value);

  return (
    <div className="flex flex-wrap -mx-3 mb-6">
      <div className="w-full px-3 animated fadeIn fast">
        <form onSubmit={onSubmit} className="relative">
          <input
            id="search"
            value={text}
            onChange={onChange}
            className="appearance-none shadow-sm block w-full bg-white text-gray-700 py-6 px-4 leading-tight focus:outline-none focus:bg-white border-b focus:border-purple-500"
            type="text"
            name="text"
            placeholder="Search Github Users..."
            autoComplete="false"
            autoFocus="true"
          />
          {githubContext.users.length > 0 && (
            <button
              onClick={githubContext.clearUsers}
              className="absolute w-40 top-0 right-0 text-sm px-4 py-2 leading-none border-2 rounded-full text-orange-600 border-orange-500 hover:border-orange-300 hover:text-orange-500 hover:bg-white mt-4 lg:mt-4 lg:mr-4 mr-2 mt-2"
            >
              Clear Search
            </button>
          )}
        </form>
      </div>
      {githubContext.users.length > 0 && (
        <div className="text-gray-700 text-lg w-2/3 mx-auto px-6 mt-6">
          <i class="fas fa-search" /> Search results for{" "}
          <span className="font-bold">{text}</span>
        </div>
      )}
    </div>
  );
};

export default Search;
