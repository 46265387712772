import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Navbar = ({ icon, title }) => {
  return (
    <nav className='flex items-center flex-wrap grad-blue-blue p-6'>
      <div className='flex items-center flex-shrink-0 text-white mr-6'>
        <span className='font-semibold text-xl tracking-tight' id='navbar-text'>
          <i className={icon} /> <Link to='/search'>{title}</Link>
        </span>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  title: 'Github Search',
  icon: 'fab fa-github'
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Navbar;
