import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Art = () => {
  return (
    <Fragment>
      <div className="looper-wave grad-blue-blue h-screen relative animated">
        <div className="fixed top-0"></div>
        <h1 className="w-full md:w-1/3 mx-4 pl-4 md-pl-0 text-white text-4xl md:text-5xl font-hairline tracking-wide pr-8 pb-4 md:pb-4 md:pt-8">
          Experimental Github user search tool.
        </h1>
        <div className="container w-full md:w-1/3  flex flex-wrap justify-center items-center animated fadeInUp">
          <Link
            to={`/search`}
            className="ml-4 flex-1 capitalize text-center block bg-white hover:bg-gray-200 rounded-full px-6 py-4 mr-4 tracking-wide text-sm md:text-lg font-semibold text-purple-600 hover:text-purple-800 shadow"
          >
            Search Github Users
          </Link>
          <Link
            to={`/about`}
            className="mr-4 flex-none text-center uppercase mx-auto block border-2 hover:bg-white rounded-full px-6 py-4 tracking-wide text-sm font-semibold text-white hover:text-purple-600"
          >
            About
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Art;
