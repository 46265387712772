import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UserItem = ({ user: { login, avatar_url, html_url, score } }) => {
  const roundScore = score.toFixed(0);

  return (
    <div className='w-full md:w-1/2 lg:w-1/3'>
      <div className='relative grad-purple-blue hover:bg-blue-600 card rounded-lg shadow-md hover:shadow-xl bg-white mx-4 pt-6 mb-8 animated fadeIn'>
        <div className=''>
          <img
            src={avatar_url}
            className='w-24 rounded-full ml-auto mr-auto shadow-lg animated pulse'
            alt={login}
          />
          <div className='px-6 py-4'>
            <h1 className='font-bold text-2xl text-center text-white'>
              {login}
            </h1>
            <div className='text-center text-sm font-semibold px-2 py-2 mr-auto ml-auto text-gray-300'>
              <i class='fas fa-trophy text-gray-300' /> {roundScore}
            </div>
          </div>
        </div>
        <div className='px-6 pb-6 rounded-b-lg'>
          <Link
            to={`/user/${login}`}
            className='text-center w-2/3 bg-gray-200 uppercase ml-auto mr-auto block hover:bg-orange-500 rounded-full px-4 py-3 tracking-wide text-sm font-semibold text-gray-600 hover:text-white'
          >
            View Profile
          </Link>
        </div>
      </div>
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserItem;
