import React, { useContext } from 'react';
import GistItem from './GistItem';
import GithubContext from '../../context/github/githubContext';

const Gists = () => {
  const githubContext = useContext(GithubContext);
  const { gists } = githubContext;
  return gists.map(gist => <GistItem gist={gist} key={gist.id} />);
};

export default Gists;
