export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_USER = 'GET_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_REPOS = 'GET_REPOS';
export const GET_GISTS = 'GET_GISTS';
export const GET_GIST = 'GET_GIST';
export const GET_CHART = 'GET_CHART';
export const SET_LOADING = 'SET_LOADING';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
