import React, { Fragment, useContext } from "react";
// CHART EXAMPLES Bar, Line, Pie, Doughnut, Scatter
import { Line } from "react-chartjs-2";
import Spinner from "../layout/Spinner";
import GithubContext from "../../context/github/githubContext";

const UserChart = () => {
  const githubContext = useContext(GithubContext);

  const { loading, repos } = githubContext;

  const langs = repos.map((d) => d.language);

  const removeNull = langs.filter((e) => e !== null);

  const distribution = removeNull.reduce(
    (acum, cur) => Object.assign(acum, { [cur]: (acum[cur] | 0) + 1 }),
    []
  );

  //console.log(removeNull);
  //console.log('Reduced', distribution);
  //console.log('Langs', langs);
  //console.log(Object.keys(distribution));
  //console.log(Object.values(distribution));

  const chartLabels = Object.keys(distribution);
  const chartData = Object.values(distribution);

  const data = {
    data: {
      labels: chartLabels,
      datasets: [
        {
          data: chartData,

          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 3,
        },
      ],
    },
  };

  //console.log('CHART IT', data);

  if (loading) return <Spinner />;
  return (
    <Fragment>
      <div>
        <Line
          data={data.data}
          options={{
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "",
              fontSize: 10,
              position: "top",
            },
            tooltips: {
              titleFontSize: 16,
              cornerRadius: 5,
              xPadding: 6,
            },
            scales: {
              yAxes: [
                {
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: { display: false },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: { display: false },
                },
              ],
            },
            gridLines: { display: false },
            legend: false,
          }}
        />
      </div>
    </Fragment>
  );
};

export default UserChart;
