import React, { useReducer } from 'react';
import axios from 'axios';
import githubContext from './githubContext';
import GithubReducer from './githubReducer';
import {
  SEARCH_USERS,
  SET_LOADING,
  CLEAR_USERS,
  GET_USER,
  GET_REPOS,
  GET_GISTS,
  GET_GIST
} from '../types';

let githubClientId;
let githubClientSecret;

if (process.env._NODE_ENV !== 'production') {
  githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
  githubClientSecret = process.env.REACT_APP_GITHUB_CLIENT_SECRET;
} else {
  githubClientId = process.env.GITHUB_CLIENT_ID;
  githubClientSecret = process.env.GITHUB_CLIENT_SECRET;
}

const GithubState = props => {
  const initialState = {
    users: [],
    user: {},
    repos: [],
    gists: [],
    gist: [],
    data: {},
    loading: false
  };

  const [state, dispatch] = useReducer(GithubReducer, initialState);

  // Search Users
  const searchUsers = async text => {
    setLoading();

    //API
    const res = await axios.get(
      `https://api.github.com/search/users?q=${text}&?client_id=${githubClientId}&client_secret=${githubClientSecret}`
    );

    dispatch({
      type: SEARCH_USERS,
      payload: res.data.items
    });
  };

  // Get User
  const getUser = async username => {
    setLoading();

    //API
    const res = await axios.get(
      `https://api.github.com/users/${username}?client_id=${githubClientId}&client_secret=${githubClientSecret}`
    );

    dispatch({
      type: GET_USER,
      payload: res.data
    });
  };

  // Get Repos
  const getUserRepos = async username => {
    setLoading();

    //API
    const res = await axios.get(
      `https://api.github.com/users/${username}/repos?per_page=20&sort=created:asc&client_id=${githubClientId}&client_secret=${githubClientSecret}`
    );

    dispatch({
      type: GET_REPOS,
      payload: res.data
    });
  };

  // Get Gists /users/:username/gists
  const getUserGists = async username => {
    setLoading();

    //API
    const res = await axios.get(
      `https://api.github.com/users/${username}/gists?since=2011-08-22T09:46:16Z&client_id=${githubClientId}&client_secret=${githubClientSecret}`
    );

    dispatch({
      type: GET_GISTS,
      payload: res.data
    });
  };

  // Get Gist
  const getGist = async id => {
    setLoading();

    //API
    const res = await axios.get(
      `https://api.github.com/gists/${id}?client_id=${githubClientId}&client_secret=${githubClientSecret}`
    );

    dispatch(
      {
        type: GET_GIST,
        payload: res.data
      },
      {
        type: GET_USER,
        payload: res.data
      }
    );
  };

  // Clear Users
  const clearUsers = () => dispatch({ type: CLEAR_USERS });

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <githubContext.Provider
      value={{
        users: state.users,
        user: state.user,
        data: state.data,
        repos: state.repos,
        gists: state.gists,
        gist: state.gist,
        loading: state.loading,
        searchUsers,
        clearUsers,
        getUser,
        getUserRepos,
        getUserGists,
        getGist
      }}
    >
      {props.children}
    </githubContext.Provider>
  );
};

export default GithubState;
