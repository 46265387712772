import React, { Fragment } from 'react';

const SkeletonUser = () => (
  <Fragment>
    <div className='w-full flex flex-wrap m-auto mt-8'>
      <div className='w-full'>
        <div className='relative card bg-white px-3 pt-6 mt-6 ph-item'>
          <div className='w-1/12 ml-6'>
            <div className='ph-avatar' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-2 big mr-4' />
            <div className='ph-col-2 big mr-4' />
            <div className='ph-col-2 big mr-4' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 empty big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-2 big mr-4' />
            <div className='ph-col-2 big mr-4' />
            <div className='ph-col-2 big mr-4' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 empty big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 empty big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 empty big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
          </div>
          <div className='ph-row pt-6 mx-4'>
            <div className='ph-col-2 empty big ' />
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default SkeletonUser;
