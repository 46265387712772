import React, { Fragment, useEffect, useContext } from 'react';
import Spinner from '../layout/Spinner';
import GithubContext from '../../context/github/githubContext';
const Gist = ({ match }) => {
  const githubContext = useContext(GithubContext);

  const { getUser, getGist, user, gist, loading } = githubContext;

  useEffect(() => {
    getGist(match.params.id);
    getUser(match.params.login);
    // eslint-disable-next-line
  }, []);

  const { id, description } = gist;

  if (loading) return <Spinner />;

  return (
    <Fragment>
      <h1>{id}</h1>
      <div>{description}</div>
      <div>{user.login}</div>
    </Fragment>
  );
};

export default Gist;
