import React, { Fragment } from 'react';

const SkeletonSearch = () => (
  <Fragment>
    <div className='w-full flex flex-wrap md:w-2/3 md:mr-auto md:ml-auto m-auto'>
      <div className='w-full md:w-1/2 lg:w-1/3'>
        <div className='relative card rounded-lg bg-white mx-3 pt-6 mb-6 ph-item'>
          <div className='w-5/12 mr-auto ml-auto'>
            <div className='ph-avatar' />
          </div>
          <div className='ph-row pt-6'>
            <div className='ph-col-4 empty big' />
            <div className='ph-col-4 big' />
            <div className='ph-col-4 empty big' />
          </div>
          <div className='ph-row mt-8 mb-8 pb-8'>
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
            <div className='ph-col-2 empty big' />
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/2 lg:w-1/3'>
        <div className='relative card rounded-lg bg-white mx-3 pt-6 mb-6 ph-item'>
          <div className='w-5/12 mr-auto ml-auto'>
            <div className='ph-avatar' />
          </div>
          <div className='ph-row pt-6'>
            <div className='ph-col-4 empty big' />
            <div className='ph-col-4 big' />
            <div className='ph-col-4 empty big' />
          </div>
          <div className='ph-row mt-8 mb-8 pb-8'>
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
            <div className='ph-col-2 empty big' />
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/2 lg:w-1/3'>
        <div className='relative card rounded-lg bg-white mx-3 pt-6 mb-6 ph-item'>
          <div className='w-5/12 mr-auto ml-auto'>
            <div className='ph-avatar' />
          </div>
          <div className='ph-row pt-6'>
            <div className='ph-col-4 empty big' />
            <div className='ph-col-4 big' />
            <div className='ph-col-4 empty big' />
          </div>
          <div className='ph-row mt-8 mb-8 pb-8'>
            <div className='ph-col-2 empty big' />
            <div className='ph-col-8 big' />
            <div className='ph-col-2 empty big' />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default SkeletonSearch;
